import React from 'react';
import ContactSearchItem from './ContactSearchItem';
import { SearchIcon } from '@heroicons/react/outline';
import EmptyState from './states/EmptyState';
import ErrorState from './states/ErrorState';
import LoadingState from './states/LoadingState';
import NotFoundState from './states/NotFoundState';
/*
https://localhost:5001/contact/search/undefined%20undefined?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiY29udGFjdCIsImNvbnRhY3ROYW1lIjoiICIsImNvbnRhY3RJZCI6Mjc1MSwiaHNJZCI6NzM0ODU0OSwiY29udGFjdFJlZiI6InVuZGVmaW5lZCIsImNvbXBhbnlJZCI6InVuZGVmaW5lZCIsImlhdCI6MTYyNzI5ODU2NiwiZXhwIjoxNjI3MzA5MzY2fQ.iLK-8YTMTLzgNo4KwYaTuFXr1fzC0wourYVGOo6b9XA&contactRef=undefined&companyId=undefined
*/
const token = new URLSearchParams(document.location.search).get("token");
const api = process.env.REACT_APP_API_SRC;

class UKContactSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            status: 'loading',
            newQuery: ''
        }
    }
    componentDidMount = () => {
      this.searchContacts(this.props.query);
      if(this.props.query !== 'undefined undefined') {
        this.setState({'newQuery': this.props.query})
      }
    }    
    async searchContacts(query = this.state.newQuery) {
        try {            
          if(query === '') { return this.setState({'status':'empty'}) }  
          if(query === 'undefined undefined') { this.setState({'status':'empty'}); this.props.navigate('/contact/search/'); return; }  
          
          this.setState({'status':'loading'});
          let requestUrl = api + '/contact/search' + '?token='+token + '&search=' + encodeURIComponent(query);
          let response = await fetch(requestUrl);
          if(response.status !== 200) { throw(response)}
          response = await response.json();
          this.setState({'data': response.items,'status':'complete'});
        } catch(response) {
          let body = response.body ? await response.text() : null; 
          if(body === 'Email not verified' || body === 'Properties not set up') { this.setState({'status':'setup_error'}) }
          else if(body === 'Trial Expired') { this.setState({'status':'trial_expired'}) }
          else if(response.status === 403) { this.setState({'status':'token_error'}) }
          else { this.setState({'status':'error'}) }
        }
    
    }
    onSearchType(value) {
      this.setState({'newQuery':value});
    }
    onSearchSubmit() {
      this.props.navigate('/contact/search/'+this.state.newQuery);
      this.searchContacts(this.state.newQuery);
    }

    render() {
        let contactListItems;
        if(this.state.status === 'complete') {
          if(this.state.data.length > 0) {
            contactListItems = this.state.data.map((result,i) => {
              if(result.appointment_count > 0) {
                return (
                    <ContactSearchItem person={result} key={i} />
                )
              }
            });
          } else {
            contactListItems = ( <NotFoundState /> )
          }
        } else if(this.state.status === 'empty') {
          contactListItems = ( <EmptyState /> )
        }
        else if(this.state.status === 'error') {
          contactListItems = ( <ErrorState /> )
        } else if(this.state.status === 'setup_error') {
          contactListItems = ( <ErrorState h1="Finish Set Up" message="Looks like you still need to complete the set up steps - head to <a href='https://app.companylookup.weaveandblend.com' target='_blank' class='text-gray-400 underline'>Your Dashboard</a> to complete set up." />)
        } else if(this.state.status === 'trial_expired') {
          contactListItems = ( <ErrorState h1="Trial Expired" message="Your trial has expired, go to <a href='https://app.companylookup.weaveandblend.com/subscribe' target='_blank' class='text-gray-400 underline'>Your Dashboard</a> to see more information about plans" />)
        } else if(this.state.status === 'token_error') {
          contactListItems = ( <ErrorState h1="Session Timeout" message="Try reloading the page. If the issue continues reach out to our support team" />)
        } else {
          contactListItems = ( <LoadingState h1="Searching..." /> )
        }
        return (
            <>
                <div className="pl-4 p-2 inline-flex group sticky top-12 mb-3">
                  <form onSubmit={e => {e.preventDefault(); this.onSearchSubmit()}}>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <div className="relative flex items-stretch flex-grow focus-within:z-10">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </div>
                        <input
                          type="text" 
                          value={this.state.newQuery} 
                          onChange={e => this.onSearchType(e.target.value)}  
                          className="text-xl font-normal focus:outline-none focus:ring-0  focus:border-green-800 block w-full rounded-none rounded-l-md pl-10 border-gray-300"
                          placeholder="Search Contacts..."
                        />
                      </div>
                      <button type="submit" className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 text-xl font-normal rounded-r-md text-white bg-green-800 hover:bg-green-600 focus:outline-none focus:ring-0">
                        <span>Search</span>
                      </button>
                    </div>
                  </form>
                </div>
                <ul className="space-y-3 pr-5">
                    {contactListItems}
                </ul>
            </>
        )
    }
}

export default UKContactSearch;