import React from 'react';
import { Router, Link } from "@reach/router";
import { ChevronLeftIcon, OfficeBuildingIcon, UserIcon} from '@heroicons/react/outline';

class Header extends React.Component {
    render() {

        return (
            <div className="p-4 group sticky top-0">
                <span className="relative z-0 inline-flex shadow-sm rounded-md">
                    <button
                        type="button"
                        onClick={(e) => window.history.back(-1) }
                        className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-green-800 focus:border-green-800"
                    >
                        <span className="sr-only">Previous</span>
                        <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                    <Link to="/company/search/"
                        type="button"
                        className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-green-800 focus:border-green-800"
                    >
                        <OfficeBuildingIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" />
                        Search Companies
                    </Link>
                    <Link to="/contact/search/"
                        type="button"
                        className="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-green-800 focus:border-green-800"
                    >
                        <UserIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" />
                        Search Contacts
                    </Link>
                </span>
                    
            </div>
        )
    }
}

export default Header;