import { SearchIcon } from '@heroicons/react/outline';
import React from 'react';
import { navigate } from "@reach/router";

import CompanySearchItem from './CompanySearchItem';
import EmptyState from './states/EmptyState';
import ErrorState from './states/ErrorState';
import LoadingState from './states/LoadingState';
import NotFoundState from './states/NotFoundState';

const token = new URLSearchParams(document.location.search).get("token");
const api = process.env.REACT_APP_API_SRC;

class UKCompanySearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            status: 'loading',
            newQuery: '',
        }
    }
    componentDidMount = () => {
        this.searchCompanies(this.props.query);
        this.setState({'newQuery': this.props.query});
    }    
    async searchCompanies(query = this.state.newQuery) {
        try {
          this.setState({'status':'loading'});
          let contactId = new URLSearchParams(document.location.search).get("contactSourceId"); 
          // Note - Getting contactSourceId here rather than the props means it only happens on the first load - otherwise we'd be stuck in a loop
          if(contactId) {
              let requestUrl = api + '/contact/getAssociations/'+ contactId + '?token='+token;
              let response = await fetch(requestUrl);
              if(response.status !== 200) { throw(response.status)}
              response = await response.json();
              console.log(response);
              if(response.error && response.error === "no_associations") {
                  // No associations
                  // could get from props but not fast enough
                  let contactName = new URLSearchParams(document.location.search).get("contactSourceName"); 
                  navigate('/contact/search/'+contactName); 
              } else {
                  query = response.companyName;
                  this.state.newQuery = query;
                  this.props.updateSource(response.companyId,response.companyName,'company');
                  if(response.companyRef) {
                    navigate('/company/view/'+response.companyRef); // If we know the company - redirect
                  }
              }
          }
          if(query === '') { return this.setState({'status':'empty'}) }
          let requestUrl = api + '/company/search?token='+token + '&search=' + query;
          let response = await fetch(requestUrl);
          if(response.status !== 200) { throw(response)}
          response = await response.json();
          this.setState({'data': response.items,'status':'complete'});
        } catch(response) {
          let body = response.body ? await response.text() : null; 
          if(body === 'Email not verified' || body === 'Properties not set up') { this.setState({'status':'setup_error'}) }
          else if(body === 'Trial Expired') { this.setState({'status':'trial_expired'}) }
          else if(response.status === 403) { this.setState({'status':'token_error'}) }
          else { this.setState({'status':'error'}) }
        }
    }
    onSearchType(value) {
      this.setState({'newQuery':value});
    }
    onSearchSubmit() {
      this.props.navigate('/company/search/'+this.state.newQuery);
      this.searchCompanies(this.state.newQuery);
    }

    render() {
        let companyListItems;
        if(this.state.status === 'complete') {
          if(this.state.data.length > 0) {
            companyListItems = this.state.data.map((result,index) => {
                return (
                    <CompanySearchItem company={result} key={result.company_number} />
                )
            });
          } else {
            companyListItems = ( <NotFoundState /> )
          }
        } else if(this.state.status === 'empty') {
          companyListItems = ( <EmptyState /> )
        } else if(this.state.status === 'error') {
          companyListItems = ( <ErrorState /> )
        } else if(this.state.status === 'token_error') {
          companyListItems = ( <ErrorState h1="Session Timeout" message="Try reloading the page. If the issue continues reach out to our support team" />)
        } else if(this.state.status === 'setup_error') {
          companyListItems = ( <ErrorState h1="Finish Set Up" message="Looks like you still need to complete the set up steps - head to <a href='https://app.companylookup.weaveandblend.com' target='_blank' class='text-gray-400 underline'>Your Dashboard</a> to complete set up." />)
        } else if(this.state.status === 'trial_expired') {
          companyListItems = ( <ErrorState h1="Trial Expired" message="Your trial has expired, go to <a href='https://app.companylookup.weaveandblend.com/subscribe' target='_blank' class='text-gray-400 underline'>Your Dashboard</a> to see more information about plans" />)
        } else {
          companyListItems = ( <LoadingState h1="Searching..." /> )
        } 
        return (
            <>
                <div className="pl-4 p-2 inline-flex group sticky top-12 mb-3">
                  <form onSubmit={e => {e.preventDefault(); this.onSearchSubmit()}}>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <div className="relative flex items-stretch flex-grow focus-within:z-10">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </div>
                        <input
                          type="text" 
                          value={this.state.newQuery} 
                          onChange={e => this.onSearchType(e.target.value)}  
                          className="text-xl font-normal focus:outline-none focus:ring-0  focus:border-green-800 block w-full rounded-none rounded-l-md pl-10 border-gray-300"
                          placeholder="Search Companies..."
                        />
                      </div>
                      <button type="submit" className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 text-xl font-normal rounded-r-md text-white bg-green-800 hover:bg-green-600 focus:outline-none focus:ring-0">
                        <span>Search</span>
                      </button>
                    </div>
                  </form>
                </div>
                <ul className="space-y-3 pr-5 pl-2">
                    {companyListItems}
                </ul>
            </>
        )
    }
}

export default UKCompanySearch;