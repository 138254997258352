import React from 'react';
import { Router, Link } from "@reach/router";
import constants from '../assets/constants.json';
import { CloudDownloadIcon, OfficeBuildingIcon, SearchIcon, UserIcon} from '@heroicons/react/outline';

class CompanyListItem extends React.Component {
    formatDate(date) {
        return new Intl.DateTimeFormat(navigator.language, {
            year: "numeric",
            month: "long",
            day: "2-digit"
        }).format(new Date(date));
    }
    render() {
        let companyStatus;
        if(this.props.company.hasOwnProperty('name')) {
            this.props.company.title = this.props.company.appointed_to.company_name;
            this.props.company.company_status = this.props.company.appointed_to.company_status;
            this.props.company.company_number = this.props.company.appointed_to.company_number;
        }
        if(this.props.company.company_status === 'active' || this.props.company.company_status === 'registered') {
            companyStatus = "bg-green-100 text-green-800 inline-flex items-center mr-3 px-3 py-0.5 rounded-full text-sm font-medium";
        } else {
            companyStatus = "bg-red-100 text-red-800 inline-flex items-center mr-3 px-3 py-0.5 rounded-full text-sm font-medium";
        }
        console.log(this.props.company)
        //let address = this.props.company.address_snippet.split(',');
        let link = '/company/view/'+this.props.company.company_number;
        return (

            <div className="flex rounded-md shadow-sm cursor-pointer hover:shadow">
                    <div className="flex items-center justify-center flex-shrink-0 w-16 text-sm font-medium text-white border-t border-b border-l rounded-l-md">
                        <OfficeBuildingIcon className="w-5 h-5 mr-2 -ml-1 text-gray-400" />
                    </div>
                    <Link to={link} className="flex items-center justify-between flex-1 truncate bg-white border-t border-b border-gray-200">
                        <div className="flex-1 p-3 truncate w-50">
                            <h3 className="text-xl truncate">{this.props.company.title}</h3>
                            <div className="flex flex-row py-2">
                                <span className={companyStatus}>{constants.company_status[this.props.company.company_status]}</span>
                                <span className="mr-3  py-0.5 text-sm text-gray-800">{constants.company_type[this.props.company.company_type]}</span>
                                <span className=" py-0.5 font-medium text-sm text-gray-800">{this.props.company.company_number}</span>
                            </div>
                            <span className="mr-3 text-sm">{constants.officer_role[this.props.company.officer_role]}</span>
                            {this.props.company.appointed_on ? (
                            <span className="mr-3 text-sm">Appointed on: {this.formatDate(this.props.company.appointed_on)}</span>
                            ) : ( '' )}
                            {this.props.company.resigned_on ? (
                            <span className="mr-3 text-sm">Resigned on: {this.formatDate(this.props.company.resigned_on)}</span>
                            ) : ( '' )}
                        </div>
                        <div className="flex-1 p-3">
                            <span className="text-sm text-gray-400">
                                <p>{this.props.company.address.premises}</p>
                                <p>{this.props.company.address.address_line_1}</p>
                                <p>{this.props.company.address.locality}</p>
                                <p>{this.props.company.address.region}</p>
                                <p>{this.props.company.address.postal_code}</p>
                            </span>                 
                        </div>
                
                    </Link>
                    <div className="flex items-center justify-center flex-shrink-0 w-16 text-sm font-medium text-white border-t border-b border-r rounded-r-md">

                        {this.props.contactId ? (

                            <button  
                            onClick={e => this.props.sync(this.props.company.company_number,this.props.company.title)} 
                            className="relative inline-flex flex-col items-center px-2 py-2 text-sm font-medium text-gray-400 "
                            >
                                <CloudDownloadIcon className="block w-10 h-5" />
                                <span className="block w-full text-gray-400 ">Save</span>
                            </button>                        
                
                        ):(

                            <button  
                            className="relative inline-flex flex-col items-center px-2 py-2 text-sm font-medium text-gray-200 cursor-not-allowed has-tooltip "
                            >
                                <span className="tooltip">Save contact to HubSpot first</span>
                                <CloudDownloadIcon className="block w-10 h-5" />
                                <span className="block w-full text-gray-200 ">Save</span>
                            </button>  


                        )}
                         
                    </div>
            </div>


        )
    }
}

export default CompanyListItem;