import { EmojiSadIcon } from '@heroicons/react/outline';

export default function NotFoundState() {

    return (
        <div className="flex h-48 justify-contents items-center">
            <span className="flex-auto flex flex-col items-center">
            <EmojiSadIcon className="h-10 w-10 m-3 text-gray-200 flex-auto" aria-hidden="true" />
            <span className=" text-base text-gray-800 flex-auto">Nothing Found</span>
            <span className=" text-sm text-gray-400 flex-auto">Try slimming your search down a little</span>
            </span>
        </div>
    )

}