import { SearchIcon } from '@heroicons/react/outline';

export default function EmptyState() {

    return (
        <div className="flex h-48 justify-contents items-center">
            <span className="flex-auto flex flex-col items-center">                 
            <SearchIcon className="h-10 w-10 m-3 text-gray-200 flex-auto" aria-hidden="true" />
            <span className=" text-base text-gray-800 flex-auto">Ready to go!</span>
            <span className=" text-sm text-gray-400 flex-auto">Start your search above</span>
            </span>
        </div>
    )

}