import React from 'react';
import CompanyListItem from './CompanyListItem';
import ModalContactAddCompany from './modals/ModalContactAddCompany';
import ModalContactAdd from './modals/ModalContactAdd';
import AlertSaved from './modals/AlertSaved';
import AlertLoading from './modals/AlertLoading';
import AlertError from './modals/AlertError';
import LoadingState from './states/LoadingState';
import ErrorState from './states/ErrorState';

import { CloudDownloadIcon, OfficeBuildingIcon, SearchIcon, UserIcon} from '@heroicons/react/outline';


const token = new URLSearchParams(document.location.search).get("token");
const api = process.env.REACT_APP_API_SRC;

class UKContactView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            status: 'loading',
            contactId: false,
            modalOpen: false,
            modalContactAdd: false,
            syncCompanyRef:'',
            syncCompanyName: '',
            existingCompanyId:'',
            existingCompanyName:'',
            alertSaved: false,
            alertLoading: false,
            alertError: false
        }
    }
    componentDidMount = () => {
        this.getContact(this.props.contactRef);
    }    
    async getContact(contactRef) {
        try {
            let requestUrl = api + '/contact/view/'+ contactRef + '?token='+token;
            let response = await fetch(requestUrl);
            if(response.status !== 200) { throw(response)}
            response = await response.json();
            this.setState({'data':response.contactData,'contactId':response.contactId,'status':'complete'});
        } catch(response) {
            let body = response.body ? await response.text() : null; 
            if(body === 'Email not verified' || body === 'Properties not set up') { this.setState({'status':'setup_error'}) }
            else if(body === 'Trial Expired') { this.setState({'status':'trial_expired'}) }
            else if(response.status === 403) { this.setState({'status':'token_error'}) }
            else { this.setState({'status':'error'}) }
          }
    }
    async saveContact(contactId) {
        try {
            this.setState({'alertLoading':true});
            this.setState({'modalContactAdd':false});
            let requestUrl = api + '/contact/save/'+contactId+'/' + this.props.contactRef + '?token='+token;
            let response = await fetch(requestUrl);
            if(response.status !== 200) { throw(response.status)}
            response = await response.json();
            this.setState({'contactId':response.contactId,'status':'complete'});
            this.setState({'alertLoading':false,'alertSaved':true,'alertTitle':'Saved','alertMessage':'Contact has been saved to HubSpot'});
          } catch(error) {
            console.error(error);
            this.setState({'alertLoading':false,'alertError':true,'status':'complete'});
          }
    }
    async startCompanySync(companyRef,companyName) {
        try {
            // Get Associations for this contact - checking if they are already linked to any companies
            let requestUrl = api + '/contact/getAssociations/'+ this.state.contactId + '?token='+token;
            let response = await fetch(requestUrl);
            if(response.status !== 200) { throw(response.status)}
            response = await response.json();
            console.log(response);
            if(response.error && response.error === "no_associations") {
                // No associations - so go ahead and sync the company
                this.setState({'syncCompanyRef':companyRef});
                this.syncCompany('undefined',true); // add association
            } else {
                // Associations already exist - so add a check by opening modal
                this.setState({
                    'modalOpen':true,
                    'syncCompanyRef':companyRef,
                    'syncCompanyName':companyName,
                    'existingCompanyName':response.companyName,
                    'existingCompanyId':response.companyId
                });
            }
        } catch(error) {
            console.error(error);
            this.setState({'alertLoading':false,'alertError':true,'status':'complete'});
        }
    }
    async syncCompany(companyId,addAssociation = false) {
        try {
            this.setState({'alertLoading':true});
            let contactId = (addAssociation) ? this.state.contactId : 'undefined';
            let requestUrl = api + '/company/save/'+companyId+'/'+this.state.syncCompanyRef+'/'+contactId+'?token='+token;
            let response = await fetch(requestUrl);
            if(response.status !== 200) { throw(response.status) }
            console.log(response);
            this.setState({'modalOpen':false});
            this.setState({'alertLoading':false,'alertSaved':true,'alertTitle':'Saved','alertMessage':'Company has been saved to HubSpot'});
        } catch(error) {
            console.error(error);
            this.setState({'alertLoading':false,'alertError':true,'status':'complete'});
        }
    }
    async startSaveContact() {
        if(this.props.contactSourceId) {
            this.setState({'modalContactAdd':true});
        } else {
            this.saveContact();
        }
    }
    render() {
        let data,companyListItems;
        if(this.state.status === 'complete') {
            data = this.state.data;
            console.log(data);
            if(data.hasOwnProperty('items')) {
                //TO DO - if loaded with company number - set that as the default (only would work from company card view)
                data.items.sort((a, b) => {
                    return (a.appointed_on > b.appointed_on) ? -1 : 1;
                });
                if(data.items.length > 0) {
                    companyListItems = data.items.map((result,index) => {
                        return (
                            <CompanyListItem company={result} key={index} contactId={this.state.contactId} sync={this.startCompanySync.bind(this)} />
                        )
                    });
                    if (data.items[0].hasOwnProperty('name_elements') && data.items[0].name_elements.forename) {
                        data.name = data.items[0].name_elements.forename + ' ' + data.items[0].name_elements.surname;
                    }
                 }
            } else {
                companyListItems = "No Companies Found";
            }
        }
        return (
            <div className="">
                <AlertSaved open={this.state.alertSaved} title={this.state.alertTitle} message={this.state.alertMessage} setClose={e => this.setState({'alertSaved':false})}  />
                <AlertLoading open={this.state.alertLoading} h1="Saving..." setClose={e => this.setState({'alertLoading':false})}  />
                <AlertError open={this.state.alertError} h1="Error Saving Data" setClose={e => this.setState({'alertError':false})}  />
                {this.state.status === 'complete' ? (
                    <>
                        <ModalContactAdd
                            open={this.state.modalContactAdd} 
                            setOpen={e => this.setState({'modalContactAdd':false})} 
                            contactName={data.name}
                            contactSourceId={this.props.contactSourceId}
                            contactSourceName={this.props.contactSourceName}
                            saveContact={this.saveContact.bind(this)}
                        />
                        <ModalContactAddCompany 
                            open={this.state.modalOpen} 
                            setOpen={e => this.setState({'modalOpen':false})} 
                            contactName={data.name}
                            companyName={this.state.syncCompanyName}
                            existingCompanyId={this.state.existingCompanyId}
                            existingCompanyName={this.state.existingCompanyName}
                            syncCompany={this.syncCompany.bind(this)}
                        />
                        <div className="py-8 px-5">
                            <div className="flex flex-row">
                                    <h1 className="flex-grow">
                                        <span className=" text-5xl text-gray-800">{data.name}</span>
                                    </h1>
                                    <div className="flex-shrink flex place-items-start">
                                        {this.state.contactId ? (
                                                <button 
                                                    onClick={e => this.saveContact(this.state.contactId)} 
                                                    className="w-60 items-center block flex justify-center px-4 py-2 rounded-sm text-lg font-normal bg-green-800 text-sm text-white hover:bg-green-600"
                                                    >
                                                    <CloudDownloadIcon className="h-5 w-5 mr-3 block" />
                                                    Update in HubSpot
                                                </button>
                                            ): (
                                                <button 
                                                onClick={e => this.startSaveContact()} 
                                                className="w-60 items-center block flex justify-center px-4 py-2 rounded-sm text-lg font-normal bg-green-800 text-sm text-white hover:bg-green-600"
                                                >
                                                    <CloudDownloadIcon className="h-5 w-5 mr-3 block" />
                                                    Save to HubSpot
                                                </button>
                                            )}
                                    </div>
                            </div>
                        </div>
                        <ul className="space-y-3 pr-5">
                            <div className="grid grid-cols-3 bg-gray-50 overflow-hidden rounded-md px-6 py-4 border-gray-50">
                                <span className="text-xs col-span-3  mb-2">Showing data captured from {data.items[0].appointed_to.company_name}</span>
                                <span className="font-medium text-gray-800 text-sm">Registered Address</span>
                                <span className="col-span-2 mb-2">
                                    {data.items[0].address.premises ? (<> {data.items[0].address.premises}, </>) : ('') }
                                    {data.items[0].address.address_line_1 ? (<> {data.items[0].address.address_line_1}, </>) : ('') }
                                    {data.items[0].address.locality ? (<> {data.items[0].address.locality}, </>) : ('') }
                                    {data.items[0].address.country ? (<> {data.items[0].address.country} </>) : ('') }
                                    {data.items[0].address.postal_code ? (<> {data.items[0].address.postal_code}</>) : ('') }
                                </span>
                                
                                {data.items[0].hasOwnProperty('country_of_residence') ? (<>
                                    <span className="font-medium text-gray-800 text-sm">Country of Residence</span>
                                    <span className="col-span-2 mb-2">{data.items[0].country_of_residence}</span>
                                </>) : ('') }
  
                                {data.items[0].hasOwnProperty('nationality') ? (<>
                                    <span className="font-medium text-gray-800 text-sm">Nationality</span>
                                    <span className="col-span-2 mb-2">{data.items[0].nationality}</span>
                                </>) : ('') }

                                {data.items[0].hasOwnProperty('occupation') ? (<>
                                    <span className="font-medium text-gray-800 text-sm">Occupation</span>
                                    <span className="col-span-2 mb-2">{data.items[0].occupation}</span>
                                </>) : ('') }

                                {data.hasOwnProperty('date_of_birth') ? (<>
                                    <span className="font-medium text-gray-800 text-sm">Year of Birth</span>
                                    <span className="col-span-2 mb-2">{data.date_of_birth.year}</span>
                                </>) : ('') }
                                {data.items[0].hasOwnProperty('name_elements') ? (<>
                                    <span className="font-medium text-gray-800 text-sm">Full Name</span>
                                    <span className="col-span-2 mb-2">{data.items[0].name_elements.title} {data.items[0].name}</span>
                                </>) : ('') }
                            </div>
                            <h3 className="text-2xl text-gray-800 px-5 my-3">Appointments</h3>
                            {companyListItems}
                        </ul>
                    </>
                ) : ( '' ) }
                {this.state.status === 'error' ? ( <ErrorState /> ) : ('')}
                {this.state.status === 'token_error' ? ( <ErrorState h1="Session Timeout" message="Try reloading the page. If the issue continues reach out to our support team" /> ) : ('')}
                {this.state.status === 'setup_error' ? ( <ErrorState h1="Finish Set Up" message="Looks like you still need to complete the set up steps - head to <a href='https://app.companylookup.weaveandblend.com' target='_blank' class='text-gray-400 underline'>Your Dashboard</a> to complete set up." /> ) : ('')}
                {this.state.status === 'trial_expired' ? ( <ErrorState h1="Trial Expired" message="Your trial has expired, go to <a href='https://app.companylookup.weaveandblend.com/subscribe' target='_blank' class='text-gray-400 underline'>Your Dashboard</a> to see more information about plans" /> ) : ('')}
                {this.state.status === 'loading' ? ( <LoadingState /> ) : ('')}
            </div>
        )
    }
}

export default UKContactView;