import React from 'react';
import { Router, Link } from "@reach/router";
import constants from '../assets/constants.json';
import { ChevronRightIcon, UserIcon} from '@heroicons/react/outline';

class ContactSearchItem extends React.Component {

    render() {
        let formatName = (name) => {
            let surname = name.split(', ')[0].toLowerCase();
            surname = surname[0].toUpperCase() + surname.slice(1);
            return name.split(', ')[1]+' '+surname;
        }
        let contactId = /officers\/(.*)\//.exec(this.props.person.links.self)[1];
        let link = '/contact/view/'+contactId;
        return (

            <>
                <Link to={link} className="flex shadow-sm rounded-md cursor-pointer hover:shadow">
                    <div className="flex-shrink-0 flex items-center border-t border-l border-b justify-center w-16 text-white text-sm font-medium rounded-l-md">
                        <UserIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" />
                    </div>
                    <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                        <div className="p-3 flex-1 w-50 truncate">
                            <h3 className="text-xl">{this.props.person.title}</h3>
                            <div className="flex flex-row py-2">
                                <span className=" py-0.5 font-medium text-sm text-gray-800">{this.props.person.description}</span>
                            </div>
                        </div>
                        <div className="flex-shrink-0 pr-2">
                            <button className="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                <ChevronRightIcon className="h-5 w-5" />
                            </button>
                        </div>
                    </div>
                </Link>
            </>
            
        )
    }
}

export default ContactSearchItem;