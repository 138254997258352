import { Puff } from 'svg-loaders-react';

export default function LoadingState({h1 = "Loading..."}) {

    return (
        <div className="flex h-48 justify-contents items-center">
            <span className="flex-auto flex flex-col items-center">
            <span className="m-3 flex-auto">
                <Puff strokeWidth="2" stroke="#059669" strokeOpacity="1" aria-hidden="true" />
            </span>
            <span className=" text-base text-gray-800 flex-auto">{h1}</span>
            </span>
        </div>
    )

}