import React from 'react';
import { Router, Link, navigate } from "@reach/router";

import Header from './components/Header';
import UKCompanySearch from './components/UKCompanySearch';
import UKCompanyView from './components/UKCompanyView';
import UKContactSearch from './components/UKContactSearch';
import UKContactView from './components/UKContactView';

import ErrorBoundary from './components/states/ErrorBoundary';


const Wrapper = ({children}) => (
  <>
    <Header />
    {children}
  </>
);
// Container Component
class App extends React.Component{
  constructor(props){
    // Pass props to parent class
    super(props);
    this.state = {
      'companySourceId': false,
      'companySourceName': false,
      'contactSourceId': false,
      'contactSourceName': false
    }
  }
  componentDidMount = () => {
    let queryParams = new URLSearchParams(document.location.search);
    if(queryParams.get('companySourceId')) {
        this.setState({'companySourceId': queryParams.get('companySourceId')});
    }
    if(queryParams.get('companySourceName')) {
      this.setState({'companySourceName': queryParams.get('companySourceName')});
    }
    if(queryParams.get('contactSourceId')) {
      this.setState({'contactSourceId': queryParams.get('contactSourceId')});
    }    
    if(queryParams.get('contactSourceName')) {
      this.setState({'contactSourceName': queryParams.get('contactSourceName')});
    }   
    if(queryParams.get('contactRef') && queryParams.get('contactRef') !== 'undefined') {
      navigate('/contact/view/'+queryParams.get('contactRef'));
    } 
    if(queryParams.get('companyRef') && queryParams.get('companyRef') !== 'undefined') {
      navigate('/company/view/'+queryParams.get('companyRef'));
    }
  }
  updateSource (sourceId,sourceName,sourceType) {
    if(sourceType === 'company') {
      this.setState({
        'companySourceId': sourceId,
        'companySourceName': sourceName
      })
    } else if(sourceType === 'contact') {
      this.setState({
        'contactSourceId': sourceId,
        'contactSourceName': sourceName
      }) 
    }
  }
  // TO DO - need a function to update sources  
  render(){

    return (
      <ErrorBoundary>
        <Router>
            <Wrapper path="/">
              <UKCompanySearch path="/company/search/*query" updateSource={this.updateSource.bind(this)} />
              <UKCompanyView path="/company/view/:companyRef" companySourceId={this.state.companySourceId} companySourceName={this.state.companySourceName} contactSourceId={this.state.contactSourceId} contactSourceName={this.state.contactSourceName} />
              <UKContactSearch path="/contact/search/*query" />
              <UKContactView path="/contact/view/:contactRef" contactSourceId={this.state.contactSourceId} contactSourceName={this.state.contactSourceName} />
            </Wrapper>
        </Router>        
      </ErrorBoundary>

    );
  }
}

export default App;