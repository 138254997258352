import React from 'react';
import { Router, Link } from "@reach/router";
import constants from '../assets/constants.json';
import { ChevronRightIcon, OfficeBuildingIcon, SearchIcon, UserIcon} from '@heroicons/react/outline';

class CompanyListItem extends React.Component {

    render() {
        let companyStatus;
        if(this.props.company.hasOwnProperty('name')) {
            this.props.company.title = this.props.company.appointed_to.company_name;
            this.props.company.company_status = this.props.company.appointed_to.company_status;
            this.props.company.company_number = this.props.company.appointed_to.company_number;
        }
        if(this.props.company.company_status === 'active' || this.props.company.company_status === 'registered') {
            companyStatus = "bg-green-100 text-green-800 inline-flex items-center mr-3 px-3 py-0.5 rounded-full text-sm font-medium";
        } else {
            companyStatus = "bg-red-100 text-red-800 inline-flex items-center mr-3 px-3 py-0.5 rounded-full text-sm font-medium";
        }
        let address = (this.props.company.address_snippet) ? this.props.company.address_snippet.split(',') : ['Not Found'];
        let link = '/company/view/'+this.props.company.company_number;
        return (
            <>
                <Link to={link} className="flex rounded-md shadow-sm cursor-pointer hover:shadow">
                    <div className="flex items-center justify-center flex-shrink-0 w-16 text-sm font-medium text-white border-t border-b border-l rounded-l-md">
                         <OfficeBuildingIcon className="w-5 h-5 mr-2 -ml-1 text-gray-400" />
                    </div>
                    <div className="flex items-center justify-between flex-1 truncate bg-white border-t border-b border-r border-gray-200 rounded-r-md">
                        <div className="flex-1 p-3 truncate w-50">
                            <h3 className="text-xl text-gray-800">{this.props.company.title}</h3>
                            <div className="flex flex-row py-2">
                                {this.props.company.company_status ? (
                                    <span className={companyStatus}>{constants.company_status[this.props.company.company_status]}</span>
                                ) : ('')}
                                <span className="mr-3  py-0.5 text-sm text-gray-800">{constants.company_type[this.props.company.company_type]}</span>
                                <span className=" py-0.5 font-medium text-sm text-gray-800">{this.props.company.company_number}</span>
                            </div>
                        </div>
                        <div className="flex-1 p-3">
                            <span className="text-sm text-gray-400">
                                
                                { address.map((addressLine,i) => 
                                        <p key={i}>{addressLine}</p>
                                )}
                                
                            </span>                   
                        </div>
                        <div className="flex-shrink-0 pr-2">
                            <button className="inline-flex items-center justify-center w-8 h-8 text-gray-400 bg-transparent bg-white rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            <span className="sr-only">Open options</span>
                                <ChevronRightIcon className="w-5 h-5" />
                            </button>
                        </div>
                    </div>
                </Link>
            </>
        )
    }
}

export default CompanyListItem;