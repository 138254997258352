import React from 'react';
import { Router, Link } from "@reach/router";
import constants from '../assets/constants.json';
import { CloudDownloadIcon, OfficeBuildingIcon, SearchIcon, UserIcon} from '@heroicons/react/outline';

class ContactListItem extends React.Component {

    render() {
        let formatName = (name) => {
            let surname = name.split(', ')[0].toLowerCase();
            surname = surname[0].toUpperCase() + surname.slice(1);
            return name.split(', ')[1]+' '+surname;
        }
        let contactId = /officers\/(.*)\//.exec(this.props.person.links.officer.appointments)[1];
        let link = '/contact/view/'+contactId;
        return (
            <div className="flex shadow-sm rounded-md cursor-pointer hover:shadow">
                    <div className="flex-shrink-0 flex items-center border-t border-l border-b justify-center w-16 text-white text-sm font-medium rounded-l-md">
                    <UserIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" />
                    </div>
                    <Link to={link} className="flex-1 flex items-center justify-between border-t border-b border-gray-200 bg-white truncate">
                        <div className="p-3 flex-1 w-50 truncate">
                             
                            <h3 className="text-xl block">{this.props.person.name}</h3>
                            <span className="text-sm">{constants.officer_role[this.props.person.officer_role]}</span>
                            {this.props.person.appointed_on ? (
                                    <span className="text-sm ml-3">Appointed on: {this.props.person.appointed_on}</span>
                                    ) : ( '' )}
                            {this.props.person.resigned_on ? (
                            <span className="text-sm ml-3">Resigned on: {this.props.person.resigned_on}</span>
                            ) : ( '' )}

                        </div>
                    </Link>
                    <div className="flex-shrink-0 flex items-center border-t border-r border-b justify-center w-16 text-white text-sm font-medium rounded-r-md">

                        {this.props.companyId ? (

                            <button  
                            onClick={e => this.props.sync(contactId,this.props.person.name)} 
                            className="relative inline-flex flex-col items-center px-2 py-2 text-sm  text-gray-400 font-medium text-sm font-medium "
                            >
                                <CloudDownloadIcon className="h-5 w-10 block" />
                                <span className=" text-gray-400 w-full block">Save</span>
                            </button>                        

                        ):(
                            <button  
                            className="cursor-not-allowed has-tooltip relative inline-flex flex-col items-center px-2 py-2 text-sm  text-gray-200 font-medium text-sm font-medium "
                            >
                                <span className="tooltip">Save company to HubSpot first</span>
                                <CloudDownloadIcon className="h-5 w-10 block" />
                                <span className=" text-gray-200 w-full block">Save</span>
                            </button>  
                        
                        )}
                    
                    </div>
            </div>
            
        )
    }
}

export default ContactListItem;