import { ExclamationCircleIcon } from '@heroicons/react/outline';

export default function ErrorState({h1 = "Couldn't reach Companies House",message="Try again in a few moments. If the issue continues reach out to our support team"}) {

    return (
        <div className="flex h-48 justify-contents items-center">
            <span className="flex-auto flex flex-col items-center">
            <ExclamationCircleIcon className="h-10 w-10 m-3 text-red-300 flex-auto" aria-hidden="true" />
            <span className=" text-base text-gray-800 flex-auto">{h1}</span>
            <span className=" text-sm text-gray-400 flex-auto" dangerouslySetInnerHTML={{__html: message}}></span>
            </span>
        </div>
    )

}