import React from 'react';
import ContactListItem from './ContactListItem';
import ModalCompanyAdd from './modals/ModalCompanyAdd';
import ModalCompanyAddContact from './modals/ModalCompanyAddContact';
import AlertSaved from './modals/AlertSaved';
import AlertLoading from './modals/AlertLoading';
import AlertError from './modals/AlertError';
import LoadingState from './states/LoadingState';
import ErrorState from './states/ErrorState';

import constants from '../assets/constants.json';
import { CloudDownloadIcon, CheckIcon, LogoutIcon, XIcon, OfficeBuildingIcon, SearchIcon, UserIcon} from '@heroicons/react/outline';

const token = new URLSearchParams(document.location.search).get("token");
const api = process.env.REACT_APP_API_SRC;

class UKCompanyView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            status: 'loading',
            companyId: false,
            showResigned: false,
            modalOpen: false,
            modalCompanyAddContact: false,
            contacts: {},
            alertSaved: false,
            alertLoading: false,
            alertError: false
        }
    }
    componentDidMount = () => {
        this.getCompany(this.props.companyRef);
    }    
    async getCompany(companyRef) {
        try {
            let requestUrl = api + '/company/view/'+ companyRef + '?token='+token;
            let response = await fetch(requestUrl);
            if(response.status !== 200) { throw(response)}
            response = await response.json();
            console.log(response);
            this.setState({'data': response.companyData,'companyId':response.companyId,'status':'complete'});
          } catch(response) {
            let body = response.body ? await response.text() : null; 
            if(body === 'Email not verified' || body === 'Properties not set up') { this.setState({'status':'setup_error'}) }
            else if(body === 'Trial Expired') { this.setState({'status':'trial_expired'}) }
            else if(response.status === 403) { this.setState({'status':'token_error'}) }
            else { this.setState({'status':'error'}) }
          }
    }
    async saveCompany(companyId) {
        try {
            this.setState({'alertLoading':true});
            this.setState({'modalOpen':false});
            let requestUrl = api + '/company/save/' + companyId + '/' + this.props.companyRef + '?token='+token;
            let response = await fetch(requestUrl);
            if(response.status !== 200) { 
                throw(response.status)
            } else {
                response = await response.json();
                this.setState({'companyId':response.companyId,'status':'complete'});
                this.setState({'alertLoading':false,'alertSaved':true,'alertTitle':'Saved','alertMessage':'Company data has been saved to HubSpot'});
            }
        } catch(error) {
            this.setState({'alertLoading':false,'alertError':true,'status':'complete'});
            console.error(error);
          }
    }
    async syncContact(contactId = 'undefined') {
        try {
            this.setState({'modalCompanyAddContact':false});
            this.setState({'alertLoading':true});
            let requestUrl = api + '/contact/save/'+contactId+'/'+this.state.syncCompanyRef+'/'+this.state.companyId + '?token='+token;
            let response = await fetch(requestUrl);
            if(response.status !== 200) { 
                throw(response.status) 
            } else {
                this.setState({'alertLoading':false,'alertSaved':true,'alertTitle':'Saved','alertMessage':'Contact has been saved to HubSpot'});
            }
        } catch(error) {
            this.setState({'alertLoading':false,'alertError':true,'status':'complete'});
            console.error(error);
        }
    }
    async startContactSync(contactRef,contactName) {
        try {
            // Get Associations for this contact - checking if they are already linked to any companies
            let requestUrl = api + '/company/getAssociations/'+ this.state.companyId + '?token='+token;
            let response = await fetch(requestUrl);
            if(response.status !== 200) { throw(response.status)}
            response = await response.json();
            console.log(response);
            if(response.error && response.error === "no_associations") {
                // No associations - so go ahead and sync the company
                this.setState({
                    'syncCompanyRef': contactRef
                })
                this.syncContact();
            } else {
                // Associations already exist - so add a check by opening modal
                this.setState({
                    'syncCompanyRef': contactRef,
                    'syncContactName': contactName,
                    'modalCompanyAddContact':true,
                    'contacts':response
                });
            }
        } catch(error) {
            console.error(error);
            this.setState({'alertLoading':false,'alertError':true,'status':'complete'});
        }
    }
    async startCompanySave() {
        if(this.props.companySourceId) {
            this.setState({'modalOpen':true});
        } else {
            this.saveCompany();
        }
    }
    formatDate(date) {
        return new Intl.DateTimeFormat(navigator.language, {
            year: "numeric",
            month: "long",
            day: "2-digit"
        }).format(new Date(date));
    }
    render() {
        let data,companyStatus,contactListItems,showResignedBlock;
        if(this.state.status === 'complete') {
            data = this.state.data;
            if(data.company.company_status === 'active' || data.company.company_status === 'registered') {
                companyStatus = "bg-green-100 text-green-800 inline-flex items-center mr-3 px-3 py-0.5 rounded-full text-sm font-medium";
            } else {
                companyStatus = "bg-red-100 text-red-800 inline-flex items-center mr-3 px-3 py-0.5 rounded-full text-sm font-medium";
            }
            if(data.officers.hasOwnProperty('items')) {

                let hasResigned = data.officers.items.find( ({ resigned_on }) => resigned_on );
                if(hasResigned) {
                    showResignedBlock = (<>
                        {this.state.showResigned ? ( 
                            <a onClick={e => this.setState({showResigned: false})} className="ml-5 text-sm text-gray-400 underline cursor-pointer">Hide Resigned</a>
                        ) : (
                            <a onClick={e => this.setState({showResigned: true})} className="ml-5 text-sm text-gray-400 underline cursor-pointer">Show Resigned</a>
                        )}
                    </>)
                }

                contactListItems = data.officers.items.map((person, i) => { 
                    if(!person.hasOwnProperty('resigned_on') || this.state.showResigned) {
                        return (
                            <ContactListItem person={person} key={i} companyId={this.state.companyId} sync={this.startContactSync.bind(this)} />
                        )
                    }
                });
            }
        }
        return (
          <div className="">
            <AlertSaved
              open={this.state.alertSaved}
              title={this.state.alertTitle}
              message={this.state.alertMessage}
              setClose={(e) => this.setState({ alertSaved: false })}
            />
            <AlertLoading
              open={this.state.alertLoading}
              h1="Saving..."
              setClose={(e) => this.setState({ alertLoading: false })}
            />
            <AlertError
              open={this.state.alertError}
              h1="Error Saving Data"
              setClose={(e) => this.setState({ alertError: false })}
            />
            {this.state.status === "complete" ? (
              <>
                <ModalCompanyAdd
                  open={this.state.modalOpen}
                  setOpen={(e) => this.setState({ modalOpen: false })}
                  companyName={data.company.company_name}
                  companySourceId={this.props.companySourceId}
                  companySourceName={this.props.companySourceName}
                  syncCompany={this.saveCompany.bind(this)}
                />
                <ModalCompanyAddContact
                  open={this.state.modalCompanyAddContact}
                  setOpen={(e) => this.setState({ modalCompanyAddContact: false })}
                  contacts={this.state.contacts}
                  contactName={this.state.syncContactName}
                  contactSourceId={this.props.contactSourceId}
                  syncContact={this.syncContact.bind(this)}
                />
                <div className="px-5 py-8">
                  <div className="flex flex-row">
                    <h1 className="flex-grow">
                      <span className="text-5xl text-gray-800 ">{data.company.company_name}</span>
                      <div className="flex flex-row py-2">
                        <span className={companyStatus}>
                          {constants.company_status[data.company.company_status]}
                        </span>
                        <span className="mr-3  py-0.5 text-sm text-gray-800">
                          {constants.company_type[data.company.type]}
                        </span>
                        <span className=" py-0.5 font-medium text-sm text-gray-800">
                          {data.company.company_number}
                        </span>
                      </div>
                    </h1>
                    <div className="flex flex-shrink place-items-start">
                      {this.state.companyId ? (
                        <button
                          onClick={(e) => this.saveCompany(this.state.companyId)}
                          className="flex items-center justify-center block px-4 py-2 text-sm text-lg font-normal text-white bg-green-800 rounded-sm w-60 hover:bg-green-600"
                        >
                          <CloudDownloadIcon className="block w-5 h-5 mr-3" />
                          Update in HubSpot
                        </button>
                      ) : (
                        <button
                          onClick={(e) => this.startCompanySave()}
                          className="flex items-center justify-center block px-5 py-3 text-sm text-lg font-normal text-white bg-green-800 rounded-sm w-60 hover:bg-green-600"
                        >
                          <CloudDownloadIcon className="block w-5 h-5 mr-3" />
                          Save to HubSpot
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 px-6 py-4 mr-5 overflow-hidden rounded-md bg-gray-50 border-gray-50">
                    {data.company.hasOwnProperty("company_status_detail") &&
                    data.company.company_status_detail !== "" ? (
                      <>
                        <span className="text-sm font-medium text-gray-800">
                          Company Status Detail
                        </span>
                        <span className="flex items-center col-span-2 mb-2">
                          {constants.company_status_detail[data.company.company_status_detail]}
                        </span>
                      </>
                    ) : (
                      ""
                    )}

                    <span className="text-sm font-medium text-gray-800">Registered Address</span>
                    <span className="col-span-2 mb-2">
                      {data.company.registered_office_address.address_line_1 ? (
                        <> {data.company.registered_office_address.address_line_1}, </>
                      ) : (
                        ""
                      )}
                      {data.company.registered_office_address.address_line_2 ? (
                        <> {data.company.registered_office_address.address_line_2}, </>
                      ) : (
                        ""
                      )}
                      {data.company.registered_office_address.locality ? (
                        <> {data.company.registered_office_address.locality}, </>
                      ) : (
                        ""
                      )}
                      {data.company.registered_office_address.country ? (
                        <> {data.company.registered_office_address.country} </>
                      ) : (
                        ""
                      )}
                      {data.company.registered_office_address.postal_code ? (
                        <> {data.company.registered_office_address.postal_code}</>
                      ) : (
                        ""
                      )}
                    </span>

                    {data.company.date_of_creation !== "" ? (
                      <>
                        <span className="text-sm font-medium text-gray-800">Date of Creation</span>
                        <span className="col-span-2 mb-2">
                          {this.formatDate(data.company.date_of_creation)}
                        </span>
                      </>
                    ) : (
                      ""
                    )}

                    <span className="text-sm font-medium text-gray-800">Nature of Business</span>
                    {data.company.hasOwnProperty("sic_codes") ? (
                      data.company.sic_codes.map((sic, i) => {
                        return (
                          <span key={i} className="col-span-2 col-start-2">
                            {constants.sic_descriptions[sic]}
                          </span>
                        );
                      })
                    ) : (
                      <span className="col-span-2 col-start-2">Not Found</span>
                    )}

                    <span className="mt-2 text-sm font-medium text-gray-800">Accounts</span>
                    <span className="flex items-center col-span-2 col-start-2 mt-2 mb-2">
                      {data.company?.accounts?.last_accounts?.made_up_to &&
                      data.company?.accounts?.last_accounts?.made_up_to !== "" ? (
                        <>
                          Last made up to{" "}
                          {this.formatDate(data.company.accounts.last_accounts.made_up_to)}
                          <a
                            className="flex items-center ml-2 text-xs text-gray-400 underline"
                            href={
                              "https://find-and-update.company-information.service.gov.uk/company/" +
                              data.company.company_number +
                              "/filing-history"
                            }
                            target="_blank"
                          >
                            View
                            <LogoutIcon className="inline-block w-4 ml-1" />
                          </a>
                          {data.company.accounts.overdue ? (
                            <>
                              <XIcon className="inline-block w-5 ml-2 mr-1 text-red-300" />{" "}
                              <span className="text-xs font-medium text-red-300">Overdue</span>
                            </>
                          ) : (
                            <>
                              <CheckIcon className="inline-block w-5 ml-2 mr-1 text-green-600" />{" "}
                              <span className="text-xs font-medium text-green-600">
                                Not Overdue
                              </span>
                            </>
                          )}
                        </>
                      ) : (
                        "Not Found"
                      )}
                    </span>

                    <span className="text-sm font-medium text-gray-800">Accounts Type</span>
                    <span className="col-span-2 mb-2">
                      {data.company?.accounts?.last_accounts ? (
                        <>{constants.account_type[data.company.accounts.last_accounts.type]}</>
                      ) : (
                        "Not Found"
                      )}
                    </span>

                    <span className="text-sm font-medium text-gray-800">
                      Confirmation Statement
                    </span>
                    <span className="flex items-center col-span-2 col-start-2 mb-2">
                      {data.company.hasOwnProperty("confirmation_statement") &&
                      data.company.confirmation_statement.hasOwnProperty("last_made_up_to") ? (
                        <>
                          Last made up to{" "}
                          {this.formatDate(data.company.confirmation_statement.last_made_up_to)}
                          <a
                            className="flex items-center ml-2 text-xs text-gray-400 underline"
                            href={
                              "https://find-and-update.company-information.service.gov.uk/company/" +
                              data.company.company_number +
                              "/filing-history"
                            }
                            target="_blank"
                          >
                            View
                            <LogoutIcon className="inline-block w-4 ml-1" />
                          </a>
                          {data.company.confirmation_statement.overdue ? (
                            <>
                              <XIcon className="inline-block w-5 ml-2 mr-1 text-red-300" />{" "}
                              <span className="text-xs font-medium text-red-300">Overdue</span>
                            </>
                          ) : (
                            <>
                              <CheckIcon className="inline-block w-5 ml-2 mr-1 text-green-600" />{" "}
                              <span className="text-xs font-medium text-green-600">
                                Not Overdue
                              </span>
                            </>
                          )}
                        </>
                      ) : (
                        "None"
                      )}
                    </span>

                    <span className="text-sm font-medium text-gray-800">Charges</span>
                    <span className="flex items-center col-span-2 mb-2">
                      {data.company.has_charges ? (
                        <>
                          {data.charges.total_count} charges{" "}
                          <a
                            className="flex items-center ml-2 text-xs text-gray-400 underline"
                            href={
                              "https://find-and-update.company-information.service.gov.uk/company/" +
                              data.company.company_number +
                              "/charges"
                            }
                            target="_blank"
                          >
                            View
                            <LogoutIcon className="inline-block w-4 ml-1" />
                          </a>
                        </>
                      ) : (
                        "No charges"
                      )}
                    </span>

                    <span className="text-sm font-medium text-gray-800">Jurisdiction</span>
                    <span className="col-span-2 mb-2">
                      {constants.jurisdiction[data.company.jurisdiction]}
                    </span>

                    <span className="text-sm font-medium text-gray-800">Previous Names</span>
                    {data.company.hasOwnProperty("previous_company_names") ? (
                      data.company.previous_company_names.map((name, i) => {
                        return (
                          <span key={i} className="col-span-2 col-start-2 mr-3">
                            {name.name} from {this.formatDate(name.effective_from)} to{" "}
                            {this.formatDate(name.ceased_on)}
                          </span>
                        );
                      })
                    ) : (
                      <span className="col-span-2 col-start-2 mr-3">None</span>
                    )}
                    <span className="mt-2 text-sm font-medium text-gray-800">
                      Persons with Significant Control
                    </span>
                    <span className="flex items-center mt-2">
                      {!data.psc.errors ? (
                        <a
                          className="flex items-center text-xs text-gray-400 underline"
                          href={
                            "https://find-and-update.company-information.service.gov.uk/company/" +
                            data.company.company_number +
                            "/persons-with-significant-control"
                          }
                          target="_blank"
                        >
                          View
                          <LogoutIcon className="inline-block w-4 ml-1" />
                        </a>
                      ) : (
                        <div className="p-5">None Found</div>
                      )}
                    </span>
                  </div>
                  <ul className="pr-5 space-y-3">
                    <h3 className="flex items-center px-5 my-3 text-2xl text-gray-800">
                      Officers {showResignedBlock}
                    </h3>
                    {data.officers.hasOwnProperty("items") && contactListItems.length > 0 ? (
                      <>{contactListItems}</>
                    ) : (
                      <div className="p-5">None Found</div>
                    )}
                  </ul>
                </div>
              </>
            ) : (
              ""
            )}
            {this.state.status === "error" ? <ErrorState /> : ""}
            {this.state.status === "token_error" ? (
              <ErrorState
                h1="Session Timeout"
                message="Try reloading the page. If the issue continues reach out to our support team"
              />
            ) : (
              ""
            )}
            {this.state.status === "setup_error" ? (
              <ErrorState
                h1="Finish Set Up"
                message="Looks like you still need to complete the set up steps - head to <a href='https://app.companylookup.weaveandblend.com' target='_blank' class='text-gray-400 underline'>Your Dashboard</a> to complete set up."
              />
            ) : (
              ""
            )}
            {this.state.status === "trial_expired" ? (
              <ErrorState
                h1="Trial Expired"
                message="Your trial has expired, go to <a href='https://app.companylookup.weaveandblend.com/subscribe' target='_blank' class='text-gray-400 underline'>Your Dashboard</a> to see more information about plans"
              />
            ) : (
              ""
            )}
            {this.state.status === "loading" ? <LoadingState /> : ""}
          </div>
        );
    }
}

export default UKCompanyView;